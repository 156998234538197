.wishlistButton {
  all: unset;
  font-size: 0.9rem;
  border-color: var(--bs-secondary);
  border-style: solid;
  border-width: 1px;
  color: var(--bs-secondary);
  border-radius: 50%;
  height: 1.6rem;
  width: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconSelected {
  &:hover {
    background-color: var(--bs-secondary);
    color: var(--bs-white) !important;
  }
}

.iconUnselected {
  &:hover {
    background-color: var(--bs-secondary);
    color: var(--bs-white);
  }
}

// onMobile Hide
@media (max-width: 768px) {
  .wishlistButtonHidden {
    display: none;
  }
}
