.slide {
  width: 30vw !important;
  min-width: 380px !important;
}

.slideVideos {
  width: 30vw !important;
  min-width: 280px !important;
}

// on mobile
@media (max-width: 768px) {
  .slide {
    width: 70vw !important;
    min-width: 300px !important;
  }

  .slideVideos {
    width: 70vw !important;
    min-width: 240px !important;
  }
}

// on tablet
@media (min-width: 768px) and (max-width: 1024px) {
  .slide {
    width: 40vw !important;
    min-width: 300px !important;
  }

  .slideVideos {
    width: 40vw !important;
    min-width: 240px !important;
  }
}
